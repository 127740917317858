import React from 'react'
import { Link } from 'gatsby'
// import starIcon from '../../assets/images/star-icon.png'
// import client1 from '../../assets/images/testimonials/client1.jpg'
// import client2 from '../../assets/images/testimonials/client2.jpg'
// import client3 from '../../assets/images/testimonials/client3.jpg'
// import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div id="testimonials" className="testimonials-area pt-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/star-icon.png?alt=media&token=792843ef-0407-4cb2-8f08-1f9e74156a72" alt="testimonial" />
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p>Hear what our clients say about our work.</p>
                </div>

                {display ? <OwlCarousel
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-testimonials-item">
                        <p>As a startup, time is literally money. Oba Coders was able to quickly discern our needs and provide exceptional developers who exceeded our expectations.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/client1.jpg?alt=media&token=e462b51b-3c67-42b8-a616-d3e9e595a9cb" alt="testimonial" />
                                <div className="title">
                                    <h3>Jamal King</h3>
                                    <span>DownShyft</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>We rely on Oba Coders for all of our hiring. They always provide a curated list of 1st class candidates, freeing up our team to focus on work not sorting through resumes.</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/client2.jpg?alt=media&token=58c20000-1c46-49cc-b744-d9b65281a1ce" alt="testimonial" />
                                <div className="title">
                                    <h3>James Easley</h3>
                                    <span>CCC</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Oba Coders has been essential to the growth of our organization. The team is insightful, thorough and they have access to an incredible network of candidates.</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src="https://firebasestorage.googleapis.com/v0/b/obacodersbrochurewebsite.appspot.com/o/client3.jpg?alt=media&token=ffead11f-1414-4078-b0a1-4324ffdbf084" alt="testimonial" />
                                <div className="title">
                                    <h3>Walter DeShields</h3>
                                    <span>Truck Slip</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}

                {/* <div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i>
                        Check Out All Reviews <span></span>
                    </Link>
                </div> */}
            </div>

            {/* <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div> */}
        </div>
    )
}

export default Testimonials;
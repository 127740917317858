import React from 'react'
import { Link } from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'

const Solutions = () => {
    return (
        <section id="aboutus" className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="StarIcon" />
                        Our Solutions
                    </span>
                    <h2>Whether you need a resource to help build your dream, or you have a dream you need our team to build, we have you covered!</h2>
                    <p>We provide a full range of IT solutions.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                {/* <Link to="/service-details-two"> */}
                                    Contract / Temp
                                {/* </Link> */}
                            </h3>
                            <p>Sometimes projects only require a helping for short period time. We got you covered.</p>

                            {/* <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                {/* <Link to="/service-details-two"> */}
                                    Temp to Hire
                                {/* </Link> */}
                            </h3>
                            <p>Short term assignments are sometimes the best way to find the perfect direct hire. </p>

                            {/* <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                {/* <Link to="/service-details-two"> */}
                                    Direct hire
                                {/* </Link> */}
                            </h3>
                            <p>Enjoy the benefits of only selecting candidates who have been subjected to thorough techinical assessments.</p>

                            {/* <Link to="/service-details-two" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Solutions;